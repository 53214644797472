import React from "react";

function Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="14"
      fill="none"
      viewBox="0 0 24 14"
      className={className}
    >
      <path
        fill="#004CFF"
        fillRule="evenodd"
        d="M7.809 12.99a1.273 1.273 0 01-1.8 0L.919 7.9H.917a1.269 1.269 0 010-1.8l5.09-5.09a1.273 1.273 0 011.8 1.799L4.89 5.727h17.291a1.273 1.273 0 010 2.546H4.891l2.918 2.918a1.273 1.273 0 010 1.8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
