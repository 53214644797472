import React from "react";
import Header from "@layouts/Header";
import SliderSection from "@sections/SliderSection";
import TestimonialsSlider from "@sections/TestimonialsSlider";
import Contact from "@sections/Contact";

import "./Testimonials.styles.scss";

const Testimonials = ({ data }) => {
  const header = data?.header || {};
  const customers = data?.customers || {};
  const review = data?.review || {};

  return (
    <div className="testimonials-page">
      <Header {...header} />

      <div className="testimonials-page__slider">
        <SliderSection {...customers} />
      </div>
      <TestimonialsSlider {...review} hasBackground />
      <Contact
        info={{
          category: "Contact",
          title: (
            <>
              Let's Get in
              <br /> Touch
            </>
          ),
          description:
            "Digital Business Analysts must combine customer experience, domain knowledge and technology to forge a new pathway to success",
          isWhite: true,
        }}
      />
    </div>
  );
};

export default Testimonials;
