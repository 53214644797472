import React, { useState } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";

import Info from "@components/Info";
import Image from "@components/Image";
import TestimonialIcon from "@components/svg/TestimonialIcon";
import PaginationArrowIcon from "@components/svg/PaginationArrowIcon";

import "./TestimonialsSlider.styles.scss";

const data = [
  {
    text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
  sed do eiusmod tempor incididunt ut labore et dolore magna
  aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  ullamco laboris nisi ut aliquip ex ea commodo consequat`,
    image: "Vector.png",
  },
  {
    text: `asd1231231`,
    image: "Vector2.png",
  },
  {
    text: `qqqqqqq1231231231231`,
    image: "Vector3.png",
  },
  {
    text: `qqqqqqq1231231231231`,
    image: "Vector4.png",
  },
  {
    text: `qqqqqqq1231231231231`,
    image: "Vector5.png",
  },
  {
    text: `qqqqqqq1231231231231`,
    image: "Vector6.png",
  },
];

const params = {
  spaceBetween: 20,
  speed: 800,
  slidesPerView: "auto",
  // loop: true,
};

const TestimonialsSlider = ({
  category,
  title,
  description,
  items = [],
  hasBackground,
}) => {
  const [swiper, updateSwiper] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  const handleChangeActiveKey = (key) => {
    swiper && swiper.slideTo(key);
    setActiveStep(key);
  };

  const descriptionFormatter = (text) => {
    try{
      let description = text.replace("*", "").replace("*", "split")
      description = description.replace("*", "").replace("*", "split")
      description = description.replace("\\", "split")
      return description.split("split").map(str => <p>{str}</p>)
    } catch (err) {
      return "Finish Typing Full Description (Requires Stars Seperating Paragraphs and backslash seperating Position and Name)"
    }
  }
  
  

  return (
    <div
      className={classNames("testimonials-slider", {
        "testimonials-slider--has-background": hasBackground,
      })}
    >
      <div className="testimonials-slider__inner container">
        <div className="testimonials-slider__main">
          <div className="testimonials-slider__left-side">
            <Info category={category} title={title} />
            <div className="testimonials-slider__pagination">
              <div
                className="testimonials-slider__arrow"
                onClick={() => {
                  handleChangeActiveKey(
                    activeStep !== 0 ? activeStep - 1 : data.length,
                  );
                }}
              >
                <PaginationArrowIcon />
              </div>
              <div
                className="testimonials-slider__arrow testimonials-slider__arrow--last"
                onClick={() => {
                  handleChangeActiveKey(
                    activeStep !== data.length - 1
                      ? activeStep + 1
                      : 0,
                  );
                }}
              >
                <PaginationArrowIcon />
              </div>
            </div>
          </div>
          <div className="testimonials-slider__right-side">
            <div className="testimonials-slider__slider">
              <TestimonialIcon className="testimonials-slider__icon" />
              <Swiper
                {...params}
                onSlideChange={(item) => {
                  handleChangeActiveKey(item.realIndex);
                }}
                onSwiper={updateSwiper}
              >
                {items?.map((item) => {
                  return (
                    <SwiperSlide>
                      <div className="testimonials-slider__text">
                        {descriptionFormatter(item?.description)}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <TestimonialIcon className="testimonials-slider__icon testimonials-slider__icon--last" />
            </div>
          </div>
        </div>
        <div className="testimonials-slider__images">
          {items?.map((item, key) => {
            return (
              <div
                className={classNames("testimonials-slider__image", {
                  "testimonials-slider__image--active":
                    activeStep === key,
                })}
                onClick={() => {
                  handleChangeActiveKey(key);
                }}
              >
                <Image data={item.image} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSlider;
